import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { Integrations } from "components/ui/extended/Integrations";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { ChatWithUs } from "components/ui/extended/ChatWithUs";
import Mobile from "components/ui/extended/Mobile";
import CustomerServiceFeatures from "components/ui/extended/CustomerServiceFeatures";
import MarketingAutomationFeatures from "components/ui/extended/MarketingAutomationFeatures";
import CustomerSatisfactionFeatures from "components/ui/extended/CustomerSatisfactionFeatures";
import { graphql } from "gatsby";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.index.seo.title")}
        description={t("pages.features.index.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat-features.jpg",
          alt: "Customerly Features - Give your customers the support they deserve",
        }}
      />

      <PlainHeader
        title={t("pages.features.index.title")}
        description={t("pages.features.index.description")}
        titleMaxWidth={650}
        descriptionMaxWidth={600}
      />

      <CustomerServiceFeatures />

      <Integrations
        title={t("pages.features.index.integrations.title")}
        description={t("pages.features.index.integrations.subtitle")}
      />

      <MarketingAutomationFeatures />

      <CustomerSatisfactionFeatures />

      <Mobile />

      <Customers />

      <RegisterForCustomerService />

      <ChatWithUs />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
